import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { LedgerService, LedgerDataService } from "../../../services";
import { CottonLedger } from "../../../models/cotton-ledger";

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-ledger-dashboard',
  templateUrl: './ledger-dashboard.component.html',
  styleUrls: ['./ledger-dashboard.component.scss']
})
export class LedgerDashboardComponent implements OnInit {

  reportStartDate: Date;
  reportEndDate: Date;
  dateLimit: Date;
  displayModule: String;

  reportObj: {
    totalPrice: number,
    totalWeight: number,
    averagePrice: number,
    farmersPrice: number,
    looseCount: number,
    tenderCount: number,
    tenderChecks: number,
    tenderRegBags: number,
    tenderSmallBags: number
  };
  reportByQualityArray: [{}];

  TList$: Observable<CottonLedger[]>;
  snackbarDurationInSeconds = 5;

  TransactionsSource: MatTableDataSource<CottonLedger>;
  TransactionColumns: string[] = ["refId", "payee", "payeeType", "transactionDate", "quality", "adjustedWeight", "ratePerUnit", "amount", "delete"];
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private ngZone: NgZone,
    private router: Router,
    private Service: LedgerService,
    private DataService: LedgerDataService,
    private _snackBar: MatSnackBar) {
    this.dateLimit = new Date();
    this.reportStartDate = new Date();
    this.reportEndDate = new Date();
    this.displayModule = "default";
    this.reportObj = {
      totalPrice: 0,
      totalWeight: 0,
      averagePrice: 0,
      farmersPrice: 0,
      looseCount: 0,
      tenderCount: 0,
      tenderChecks: 0,
      tenderRegBags: 0,
      tenderSmallBags: 0
    };
    this.reportByQualityArray = [{}];
    // this.getReportObjByDates();
  }

  ngOnInit(): void {
  }

  // Create new transaction
  newTransaction() {
    // Navigate to ledger form
    this.ngZone.run(() => {
      this.router.navigate(["/ledgerentry"], { skipLocationChange: true });
    });
  }

  // Create new cotton purchase transaction
  newCottonTransaction() {
    // Navigate to ledger form
    this.ngZone.run(() => {
      this.router.navigate(["/cottonledgerentry"], { skipLocationChange: true });
    });
  }

  getReportObjByDates() {
    this.displayModule = 'report';
    this.DataService.getTransactionsWithDateRange(this.reportStartDate.setHours(0, 0, 0, 0), this.reportEndDate.setHours(23, 59, 59, 0))
      .subscribe((data) => {
        let totalPrice = 0;
        let farmersPrice = 0;
        let totalWeight = 0;
        let averagePrice = 0;
        let looseCount = 0;
        let tenderCount = 0;
        let tenderChecks = 0;
        let tenderRegBags = 0;
        let tenderSmallBags = 0;
        let qualityObj = {};
        data.forEach((record: CottonLedger) => {
          if (record.product !== "COTTON") return;
          if(record.purchaseType === "LOOSE") {
            looseCount += 1;
            // Calculate Farmers category
            if(record.payeeType === "FARMER") {
              farmersPrice += +record.amount;
            }
          }
          else if (record.purchaseType === "TENDER") {
            tenderCount += 1;
            if (record.tenderBagType === "CHECKS") {
              tenderChecks += +record.tenderBagCount;
            }
            else if (record.tenderBagType === "BAGS_REGULAR") {
              tenderRegBags += 1;
            }
            else if (record.tenderBagType === "BAGS_SMALL") {
              tenderSmallBags += 1;
            }
          }
          totalPrice = totalPrice + +record.amount;
          totalWeight = totalWeight + +record.adjustedWeight;
          // Group by quality
          qualityObj[record.quality] = qualityObj[record.quality] || {};
          let currentPrice = qualityObj[record.quality].qualityTotalPrice || 0;
          let currentWeight = qualityObj[record.quality].qualityTotalWeight || 0;
          let newPrice = currentPrice + +record.amount;
          let newWeight = currentWeight + +record.adjustedWeight;
          let averagePrice = newPrice / newWeight;
          qualityObj[record.quality] = {
            "qualityTotalPrice": newPrice,
            "qualityTotalWeight": newWeight,
            "qualityAveragePrice": averagePrice
          };

        });

        totalWeight = totalWeight;
        averagePrice = totalPrice / (totalWeight);
        this.reportObj["qualityCats"] = qualityObj;

        this.reportObj = { ...this.reportObj, totalPrice, totalWeight, averagePrice, farmersPrice, looseCount, tenderCount, tenderChecks, tenderRegBags, tenderSmallBags };
        // console.log(this.reportObj);
      });
  }

  getListByDates() {
    this.displayModule = 'list';
    this.TList$ = this.DataService.getTransactionsWithDateRange(this.reportStartDate.setHours(0, 0, 0, 0), this.reportEndDate.setHours(23, 59, 59, 0)).pipe(
      map(TList => {
        TList.forEach((item) => {
          item["refId"] = item["rstId"] || item["receiptId"];
        });
        return TList;
      })
    );
    this.TList$.subscribe(List => {
      this.TransactionsSource = new MatTableDataSource(List);
      this.TransactionsSource.sort = this.sort;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.TransactionsSource.filter = filterValue.trim().toLowerCase();
  }

  /* List operations */
    // Delete ledger entry
    deleteEntry(entry) {
      this.Service.deleteLegerEntry(entry.id);
      this.openSnackBar(`Deleted ledger entry ${entry.payee} - ${entry.refId} - ${new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'INR'}).format(entry.amount)}`);
    }
  
    // Snackbar
    openSnackBar(message) {
      this._snackBar.open(message, '', {
        duration: this.snackbarDurationInSeconds * 1000
      });
    }

  /* End of list operations */

  // Goto ledger list
  gotoLedgerMain() {
    // Reset form if filled and cancelled
    this.ngZone.run(() => {
      this.router.navigate(["ledgermain"]);
    });
  }

}